import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CNewsList,
  CPagingList,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';

// markup
const SubPage = ({
  data,
  pageContext,
}: {
  data: any;
  pageContext: {
    limit: number;
    skip: number;
    totalCount: number;
    current: number;
  };
}) => {
  const frontmatter = {
    title: 'お知らせ',
    description:
      '京の温所(きょうのおんどころ)からのお知らせを掲載しております。更新情報や、イベント情報などをお届けいたします。',
  };

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <main id="news" className="under wd">
        <section id="topNews">
          <div className="secIn">
            <h2 className="tit"><span wovn-ignore="true">NEWS</span><span className="sub">京の温所からのお知らせ</span></h2>
            <CNewsList data={infoChoice(infoGet(),
              pageContext.limit + pageContext.skip,
              pageContext.skip)} />
            <CPagingList
              totalCount={pageContext.totalCount}
              path="/news/"
              perPage={pageContext.limit}
              current={pageContext.current}
            />
          </div>

        </section>
      </main>
    </Layout>
  );
};

export default SubPage;
